/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

.mat-checkbox-layout { white-space: normal !important; }

/* Placeholder transparency to pass color contrast. */
.mat-form-field-label { color: rgba(0,0,0,.60); }

.badge {
  background-color: #666;
}

.column-form-container {
  display: flex;
  flex-direction: column;
}

.column-form-container > * {
  /* width: 100%; */
  width: 300px;
}

.autocomplete-form-container {
  display: flex;
  flex-direction: column;
}

.autocomplete-form-container > * {
  /* width: 100%; */
  width: 250px;
}

.twocolumn-form-container {
  display: flex;
  flex-direction: column;
}

.twocolumn-form-container > * {
  width: 600px;
}

/* Fits within a twocolumn-form-container. */
.form-column > * {
  width: 298px;
}

.button-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dialog-close-button {
  float: right;
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  outline: 0;
}

.mat-dialog-container {
  overflow-x: hidden;
}

.toolbar-space-filler {
  /* This fills the remaining space, by using flexbox.
     Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.mat-card-subtitle {
    color: black;
    text-decoration: underline;
    size: 14em;
}

.top-buffer {
  margin-top: 30px;
}

/*.mat-toolbar-row  {*/
/*  display: inline !important;*/

/*}*/

